.servicos-home {
  //padding-top: 0px;
}

// Bloco serviço
.servico {
  position: relative;
  overflow: hidden;
  max-width: 350px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;

  // Foto
  figure img {
    //transform: scale(1.2);
    transition: .5s ease-out;
  }

  &:hover figure img {
    transform: scale(1.2);
  }

  // Máscara
  span {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(to top, rgba(#000, .9), transparent);
    transition: .3s ease-out;
    text-align: center;
    padding: 20px 20px;
    padding-top: 150px;
  }

  &:hover span {
    //background: rgba(#000, .6);
  }

  // Título
  h2 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: #FFF;
    text-shadow: 2px 2px 2px rgba(#000, .3);
    margin-bottom: 15px;

    // MOBILE
    @include mq(s) {
      margin-top: 0px;
      font-size: 18px;
    }
  }

  // Botão
  .btnx {
    opacity: 0;
    transform: scale(.5);

    // MOBILE
    @include mq(s) {
      opacity: 1;
      transform: scale(1);
    }
  }

  &:hover .btnxx {
    opacity: 1;
    transform: scale(1);
  }
}

// Carrosel
.carrosel-servicos {
  //margin-top: px;

  // Bloco serviço
  .servico {
    display: block;
    width: 100%;
    margin: 5px 10px;

    // MOBILE
    @include mq(s) {
      margin: 20px 0px;
    }
  }
}

// Vídeo caixa
.video-caixa {
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 350px;
  overflow: hidden;
  background: $cor_fundo2;

  // TABLET
  @include mq(m) {
    height: 310px;
  }

  // MOBILE
  @include mq(s) {
    height: auto;
  }

  // Vídeo
  iframe {
    width: 100%;
    height: 300px;

    // MOBILE
    @include mq(s) {
      height: 250px;
    }

    // TABLET
    @include mq(m) {
      height: 250px;
    }
  }

  // Título
  h2 {
    float: left;
    width: 100%;
    padding: 5px 20px;
    color: $cor_fonte_escura;
    font-weight: 400;
    font-size: 16px;
    background: $cor_fundo2;

    // MOBILE
    @include mq(s) {
      font-size: 16px;
      padding: 10px;
    }

    // TABLET
    @include mq(m) {
      font-size: 16px;
      padding: 10px;
    }
  }
}
